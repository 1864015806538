<template>
  <div class="organization-settings tw-w-full">
    <app-heading class="tw-text-h2 tw-m-0">
      Organization Settings
    </app-heading>

    <el-tabs
      ref="tabs"
      v-scrollable:x
      :value="activeTab"
      :before-leave="handleBeforeLeaveTab"
      class="organization-settings-tabs md:tw-mt-7 tw-mt-space-4"
      :class="{ 'freeze': transition == '' }"
      @tab-click="handleTabClick">
      <el-tab-pane
        v-for="item in tabs"
        :key="`tab-${item.name}`"
        :label="item.label || item.name"
        :name="item.name"
        class="organization-settings-tab-item" />
    </el-tabs>
    <transition mode="out-in" :name="transition">
      <router-view :key="$route.name" v-bind="routerProps" v-on="routerListeners" />
    </transition>
  </div>
</template>

<script>
import AppHeading from '@/components/AppHeading.vue';
import { ORGANIZATION_SETTINGS_TABS } from '@/router/groups/organization-settings';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';

export default {
  name: 'OrganizationSettingsLayout',
  components: { AppHeading },
  data() {
    return {
      transition: '',
      activeTabIndex: 0,
      hasReceivingOrgs: false,
      partnershipData: {
        data: [],
        pagination: {}
      }
    };
  },
  computed: {
    routerProps() {
      if (['organization-settings-partnerships', 'organization-settings-permissions'].includes(this.$route.name)) {
        return {
          partnershipData: this.partnershipData.data,
          pagination: this.partnershipData.pagination
        };
      }
      return {};
    },
    routerListeners() {
      if (['organization-settings-partnerships', 'organization-settings-permissions'].includes(this.$route.name)) {
        return { 'get-partnerships': this.getPartnerships };
      }
      return {};
    },
    tabs() {
      return ORGANIZATION_SETTINGS_TABS.filter(({ name }) => {
        if (!this.hasReceivingOrgs && name === 'organization-settings-partnerships') return false;
        if (!this.isAffiliate && ['organization-settings-domains', 'organization-settings-conversions'].includes(name)) {
          return false;
        }

        if (
          ['organization-settings-permissions', 'organization-settings-partnerships'].includes(name) &&
          !this.$can(this.$permissions.VIEW_COMPANY_PARTNERSHIP_LIST)
        ) {
          return false;
        }

        return true;
      });
    },
    activeTab() {
      return this.$route.name;
    },
    userType() {
      return this.$store.getters.userType;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    shouldPreventNavigation() {
      return this.$store.getters.shouldPreventNavigation;
    }
  },
  created() {
    this.checkHasReceivingOrgs();
  },
  methods: {
    handleTabClick({ name, index }) {
      this.transition = index > this.activeTabIndex ? 'slide-left' : 'slide-right';
      this.$router.push({ name });
      this.activeTabIndex = index;
    },
    handleBeforeLeaveTab() {
      if (this.shouldPreventNavigation) return Promise.reject(new Error('shouldPreventNavigation'));
      return Promise.resolve();
    },
    async checkHasReceivingOrgs() {
      const receiveingOrgs = await this.getPartnerships({ params: { filter: { partnership_type: 'receiving' } } }, false);
      this.hasReceivingOrgs = !!receiveingOrgs.length;
      if (!this.hasReceivingOrgs && this.$route.name === 'organization-settings-partnerships') this.$router.replace({ name: 'organization-settings' });
    },
    async getPartnerships(data = { search: '', params: { filter: { org_type: '', partnership_type: '' }, page: 1 } }, setData = true) {
      try {
        data.params.filter = _pickBy(data.params.filter, _identity);
        const response = await this.$api.permissions.fetchPartnerships({ fullResponse: true, q: data.search, params: data.params });
        if (!setData) return response.data;
        this.partnershipData.data = response.data;
        this.partnershipData.pagination = response._meta;
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      }
    }
  }
};
</script>

<style lang="scss">
.organization-settings {
  @include page-layout;

  .el-tabs .el-tabs__nav {
    @apply tw-flex;
  }

  .el-tabs .el-tabs__nav .el-tabs__active-bar {
    @apply tw-h-space-3px tw-bg-jb-indigo;
  }

  .el-tabs .el-tabs__item {
    @apply tw-flex tw-items-center tw-h-layout-4 tw-text-sm tw-text-jb-grey-600;

    &.is-active {
      @apply tw-text-jb-ink tw-font-semibold;
    }
  }
}
</style>
